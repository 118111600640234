import React from 'react';
import styled from 'styled-components';
import Layout from 'components/layout';
import IntroBlock from 'components/pages/Blog/Intro/IntroBlock';
import ArticlesListBlock from 'components/pages/Blog/PostsList/PostsListBlock';
import PaginationBlock from 'components/pages/Blog/Pagination/PaginationBlock';
import LinkBlocks from 'components/LinkBlock/LinkBlocks';
import LinkBlockBackground from 'components/pages/Solution/Marketing/LinkBlockBackground';
import { Grid } from '@material-ui/core';
import { breakpoint } from '../../utils/mediaQueries';
import ChipsBlock from 'components/pages/Blog/ChipsBlock/ChipsBlock';

const LinkGrid = styled(Grid)`
  margin-top: -4rem;
  position: relative;
  padding-top: 10rem;
  padding-bottom: 4rem;
  background-position: top;
  ${breakpoint.down('lg')`
  padding-top: 6rem;padding-bottom: 2rem;margin-top: 0;`}
  ${breakpoint.down('md')`
  padding-bottom: 4rem;`}
  ${breakpoint.down('sm')`padding-top:0;padding-bottom: 6rem;`};
`;

const BlogPage = props => {
  const categories = [
    {
      value: 'customerRelation',
      label: 'navigation.customerRelation.title',
    },
    {
      value: 'insights',
      label: 'navigation.insights.title',
    },
    {
      value: 'useCases',
      label: 'navigation.blogUseCases.title',
    },
  ];
  const activeCategory = categories.find(cat => cat.value === props.activeCategory);
  const introBlocksData = {
    markdownContent: props.data.dataBlockIntroTitle,
  };
  const articlesListData = {
    articlesList: props.data.dataArticlesList.posts,
    // articlesThumbnails: props.data.dataArticlesThumbnails.nodes,
  };
  const linkBlocksData = {
    markdownContent: props.data.dataBlockLinkBody,
  };
  return (
    <Layout location={props.location} seo={props.seo}>
      <IntroBlock
        data={introBlocksData.markdownContent}
        lang={props.pageContext.lang}
      />
      <ChipsBlock
        activeCategory={activeCategory}
        data={categories}
        lang={props.pageContext.lang}
      />
      <ArticlesListBlock
        data={articlesListData}
        lang={props.pageContext.lang}
        location={props.location}
      />
      {props.pageContext.numPages > 1 && (
        <PaginationBlock
          numPages={props.pageContext.numPages}
          lang={props.pageContext.lang}
          currentPage={props.pageContext.currentPage}
        />
      )}
      <LinkGrid>
        <LinkBlocks
          data={linkBlocksData.markdownContent.nodes}
          lang={props.pageContext.lang}
        />
        <LinkBlockBackground />
      </LinkGrid>
    </Layout>
  );
};

export default BlogPage;
