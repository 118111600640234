import React from 'react';
import styled from 'styled-components';
import { range } from 'lodash';
import { Link } from 'gatsby';
import { Container } from 'components/basics';
import { colors, textBold } from 'styles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

const ContainerBlock = styled(Container)`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10em;
`;

const PageNumberBlock = styled.span`
  margin-right: 1em;
  p {
    color: ${({ currentPage, page, isText }) =>
      page === currentPage && !isText ? colors.black : colors.purple} !important;
  }
`;

const PageNumberLink = styled(Link)`
  // margin-right: 1em;
  text-decoration: none;

  &:visited {
    color: purple;
  }
`;

const PageNumber = styled(Typography)`
  font-size: ${props => props.theme.typography.body2.fontSize};
  transition: all 0.2s ease-in-out;
  &:hover {
    color: ${colors.purple};
    transition: all 0.2s ease-in-out;
  }
  ${textBold};
`;

const PaginationBlock = ({ numPages, lang, currentPage }) => {
  const pagesArray = range(1, numPages + 1);
  return (
    <ContainerBlock container>
      {currentPage !== pagesArray[0] && (
        <PageNumberBlock isText>
          <PageNumberLink
            to={`/${lang}/blog${
              currentPage - 1 > 1 ? `/${currentPage - 1}` : ''
            }`}
          >
            <PageNumber>
              <FormattedMessage id="blog.previous" />
            </PageNumber>
          </PageNumberLink>
        </PageNumberBlock>
      )}
      {pagesArray.map(page => (
        <PageNumberBlock
          currentPage={currentPage}
          page={page}
          key={`page${page}`}
        >
          <PageNumberLink to={`/${lang}/blog${page > 1 ? `/${page}` : ''}`}>
            <PageNumber>{page}</PageNumber>
          </PageNumberLink>
        </PageNumberBlock>
      ))}
      {currentPage !== pagesArray[pagesArray.length - 1] && (
        <PageNumberBlock isText>
          <PageNumberLink to={`/${lang}/blog/${currentPage + 1}`}>
            <PageNumber>
              <FormattedMessage id="blog.next" />
            </PageNumber>
          </PageNumberLink>
        </PageNumberBlock>
      )}
    </ContainerBlock>
  );
};

export default PaginationBlock;
