import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import { colors } from 'styles';
import { Grid, Chip } from '@material-ui/core';

const StyledGrid = styled(Grid)`
  padding: 2rem;
`;
const StyledChip = styled(Chip)`
  margin: 0.5rem 1rem;
  padding: 1.5rem 1rem;
  background-color: rgba(173, 147, 160, 0.1);
  color: ${colors.black};
  font-family: ${props => `${props.theme.typography.fontFamily}
`};
`;

const ActiveChip = styled(StyledChip)`
  background-color: ${colors.orange};
  color: ${colors.white};
  &:hover {
    background-color: ${colors.orange};
  }
`;

const ChipTitle = styled.h1`
  font-weight: normal;
  font-size: ${props => `${props.theme.typography.body1.fontSize}px`};
`;

const ChipLink = styled(Link)`
  text-decoration: none;
`;

const ChipsBlock = ({ activeCategory, data, lang }) => {
  return (
    <Grid container justify="center" alignItems="center">
      <StyledGrid
        container
        item
        xs={'auto'}
        sm={12}
        md={12}
        lg={12}
        justify={'center'}
      >
        {data.map((chip, index) => {
          if (activeCategory && chip.value === activeCategory.value) {
            return (
              <ChipLink to={`/${lang}/blog`}>
                <ActiveChip
                  label={
                    <ChipTitle>
                      <FormattedMessage id={chip.label} />
                    </ChipTitle>
                  }
                  key={`Chip${index}`}
                  clickable
                />
              </ChipLink>
            );
          } else {
            return (
              <ChipLink to={`/${lang}/blog/${chip.value}`}>
                <StyledChip
                  label={
                    <ChipTitle>
                      <FormattedMessage id={chip.label} />
                    </ChipTitle>
                  }
                  key={`Chip${index}`}
                  clickable
                />
              </ChipLink>
            );
          }
        })}
      </StyledGrid>
    </Grid>
  );
};

export default ChipsBlock;
