import React from 'react';
import styled from 'styled-components';
import Markdown from 'src/utils/markdownToComponent';
import Typography from '@material-ui/core/Typography/Typography';
import { Grid } from '@material-ui/core';
import { textBold } from 'styles';
import { Container, Content } from 'components/basics';
import { breakpoint } from 'src/utils/mediaQueries';
import { FormattedMessage } from 'react-intl';

const ContentBlock = styled(Grid)`
  ${Content}
`;

const TitleBlock = styled(Typography)`
  ${breakpoint.down('md')`text-align:left`}
  ${breakpoint.down('sm')`    font-size: ${props =>
    `${props.theme.typography.h5.fontSize}px`};`}
  ${textBold}
  span {
    color: ${props => props.theme.palette.primary.main};
  }
`;

const ParagraphBlock = styled(Grid)`
  margin-top: 4rem;
  ${breakpoint.down('md')`margin-top: 0rem`}
  p {
    ${breakpoint.down('sm')`    font-size: ${props =>
      `${props.theme.typography.body2.fontSize}px`};`}
  }
`;

const IntroBlock = ({ data, lang }) => (
  <Container>
    <ContentBlock container spacing={40} justify="center">
      <Grid item xs={12} sm={12} md={6} lg={6} xl={7}>
        <TitleBlock
          align="right"
          variant="h3"
          dangerouslySetInnerHTML={{ __html: data.frontmatter.title }}
        />
      </Grid>
      <ParagraphBlock item xs={12} sm={12} md={6} lg={5}>
        <Markdown>{data.rawMarkdownBody}</Markdown>
      </ParagraphBlock>
    </ContentBlock>
  </Container>
);

export default IntroBlock;
